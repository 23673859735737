import { Controller } from "@hotwired/stimulus"

const PLANNER_CHANGES = "changes"

let plannerStorage = JSON.parse(localStorage.getItem("planner_data")) || {}
let plannerSessionId = null

export default class extends Controller {
  static targets = [ "draggable" ]

  dragstart(event) {
    let ePos=this.eventPosition(event)
    event.dataTransfer.setData("application/drag-key", event.target.getAttribute("data-todo-id"))
    event.dataTransfer.effectAllowed = "move"
    console.log(ePos);
  }

  dragover(event) {
    event.preventDefault()
    return true
  }

  dragenter(event) {
    event.preventDefault()
  }

  drop(event) {
    var data = event.dataTransfer.getData("application/drag-key")
    const dropTarget = event.target
    const draggedItem = this.element.querySelector(`[data-todo-id='${data}']`);
    const positionComparison = dropTarget.compareDocumentPosition(draggedItem)
    if ( positionComparison & 4) {
        event.target.insertAdjacentElement('beforebegin', draggedItem);
    } else if ( positionComparison & 2) {
        event.target.insertAdjacentElement('afterend', draggedItem);
    }
    event.preventDefault()
  }

  dragend(event) {
  }



  // relocateElement(e) {
  //   let ePos=this.eventPosition(e)
  //   console.log(ePos);
  //   this.draggableTargets.forEach(el => {
  //     console.log(el)
  //     el.style.marginLeft = Math.abs(ePos['x'] - el.style.left)+"px";
  //     el.style.marginTop = Math.abs(ePos['y'] - el.style.top)+"px";
  //   });
  // }

  eventPosition(e) {
    return {
      'x': e.clientX,
      'y': e.clientY
    }
  }

  initialize() {
    // console.log("lalalal")
  }
}