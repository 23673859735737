import { Application } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

const application = Application.start()

Rails.start();

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

setTimeout(()=>{ 
  let systemMesagesAlertBlock = document.querySelector('#remote-system-messages .alert')
  if (systemMesagesAlertBlock)
    systemMesagesAlertBlock.classList.toggle("visually-hidden");
}, 3000);

export { application }