import { Controller } from "@hotwired/stimulus"

const EDITABLE_CHANGES = "editable_changes"

let searchParams = new URLSearchParams(window.location.href);
let actioneryStorage = JSON.parse(localStorage.getItem("actionery_data")) || {}
let actionerySetName = null
let actionerySetId = null
let submitButton = null

export default class extends Controller {
  static targets = [ "editable", "validable", "expectable" ]

  waitToReload(e) {
    this.expectableTargets.forEach(el => {
      if ( e.currentTarget == el.parentElement ) {
        el.classList.add("hidden");
        el.nextElementSibling.classList.remove("hidden")
      }
    });
  }

  validateInputs(e) {
    this.validableTargets.forEach(el => {
      let pClassList = el.parentElement.classList;
      let genericMsg = document.getElementById("generic-message");
      if ( ! el.value ) {
        pClassList.add("field_with_errors")
        e.preventDefault();
        genericMsg.classList.remove("hidden")
      } else {
        pClassList.remove("field_with_errors")
        genericMsg.classList.add("hidden")
      }
    });
  }

  removeInput(e) {
    this.editableTargets.forEach(el => {
      let originalValue=el.getAttribute("editable-original");
      if ( this.targetMatch(el,e) ) {
        if (el.getAttribute("editable-state") == "remove" ) {
          el.removeAttribute("editable-state");
          el.value=originalValue;
        } else {
          el.setAttribute("editable-state","remove");
          el.value=""
          this.activeEdition(el,e.currentTarget.previousElementSibling)
        }
      }
      this.checkChanges();
    });
  }

  enableInput(e) {
    e.preventDefault();
    let c = e.currentTarget
    this.editableTargets.forEach(el => {
      if ( this.targetMatch(el,e) ) {
        if ( el.getAttribute("readonly") ) {
          this.activeEdition(el,c);
        } else {
          el.setAttribute("readonly","readonly")
          el.value=el.getAttribute("editable-original");
          this.showIcon(c,"edit");
          c.nextElementSibling.classList.remove("hidden")
        }
      }
      this.checkChanges();
    });

  }

  editInput() {
    this.editableTargets.forEach(el => {
      this.checkChanges();
    });
  }

  targetMatch(el,ev) {
    return ( el.getAttribute("id") == ev.currentTarget.getAttribute("editable-match") )
  }

  showIcon(control,classIcon) {
    control.childNodes.forEach((node)=>{
      if ( node.nodeType != Node.TEXT_NODE ) {
        if ( node.classList.contains(classIcon) ) {
          node.classList.remove("hidden")
        } else {
          node.classList.add("hidden");
        }
      }
    })
  }

  showSubmit(total) {
    submitButton.removeAttribute("disabled");
    let classList = submitButton.classList;
    var changedText=( total == 1 ? " cambio" : " cambios" )
    var title="Confirmar ";
    
    classList.remove("hidden");

    if ( document.querySelector('input[value="confirm"]') ) {
      title = "Guardar "
      classList.remove("btn-outline-danger");
      classList.add("btn-outline-primary");
    } else {
      classList.add("btn-outline-danger");
      classList.remove("btn-outline-primary");
    }
    submitButton.value=title+total+changedText
  }

  activeEdition(el,c) {
    el.removeAttribute("readonly")
    el.style.height="41px";
    this.showIcon(c,"back");
    let bin = c.nextElementSibling
    if (bin)
      bin.classList.add("hidden");
  }

  checkChanges() {
    let editableChanges={};

    document.querySelectorAll('[data-actionery-target="editable"]:not([readonly="readonly"])').forEach((editable)=>{
      if ( editable.value != editable.getAttribute("editable-original")) {
        editable.classList.add("editable-dirty")
        editableChanges[editable.getAttribute("id")] = editable.value;
      } else {
        editable.classList.remove("editable-dirty")
      }
    });

    let totalChanged=Object.keys(editableChanges).length

    if ( totalChanged > 0 ) {
      this.showSubmit(totalChanged)
    } else {
      submitButton.setAttribute("disabled", "disabled")
      submitButton.classList.add("hidden");
    }
    if ( Object.keys(editableChanges).length > 0 ) {
      actioneryStorage[actionerySetName][actionerySetId][EDITABLE_CHANGES] = editableChanges;
    } else {
      delete actioneryStorage[actionerySetName][actionerySetId][EDITABLE_CHANGES] 
    }

    localStorage.setItem("actionery_data",JSON.stringify(actioneryStorage))
  }

  initialize() {

    let currentURLAttr = "data-current-url"
    let currentUrl = document.querySelector("["+currentURLAttr+"]")

    actionerySetName = document.getElementById("actionery_set_name").value
    actionerySetId =  document.getElementById("actionery_set_id").value
    submitButton = document.querySelector('[type="submit"]');
    
    if ( ! actioneryStorage.hasOwnProperty(actionerySetName) ) {
      actioneryStorage[actionerySetName] = {}
    }

    if ( ! actioneryStorage[actionerySetName].hasOwnProperty(actionerySetId) ) {
      actioneryStorage[actionerySetName][actionerySetId] = {}
    }
    
    let dsv = actioneryStorage[actionerySetName][actionerySetId]
    
    for ( var dsk in actioneryStorage[actionerySetName][actionerySetId]) {
      if ( dsk == EDITABLE_CHANGES ) {
        var editableChanges = dsv[dsk];
        var editableLength = Object.keys(editableChanges).length
        if ( editableLength > 0 ) {
          for ( var k in editableChanges) {
            var editable = document.getElementById(k);
            if ( editable ) {
              var control = document.querySelector('[href][editable-match="'+k+'"]')
              if ( control ) this.activeEdition(editable,control)
              editable.value = dsv[dsk][k];
              editable.classList.add("editable-dirty")
            }
          }
          this.showSubmit(editableLength)
        } else {
          delete actioneryStorage[actionerySetName][actionerySetId][EDITABLE_CHANGES]
        }
      }
    }

    localStorage.setItem("actionery_data",JSON.stringify(actioneryStorage))

    if ( currentUrl ) {
      currentUrl = currentUrl.getAttribute(currentURLAttr)+"?updated"
      let updated = searchParams.has(currentUrl);
      if (updated) {
        let actioneryStorage = JSON.parse(window.localStorage.getItem("actionery_data")) || {};
        let updatedEnvironment = searchParams.get(currentUrl)
        if ( actioneryStorage.hasOwnProperty('environments') ) {
          delete actioneryStorage['environments'][updatedEnvironment]
          window.localStorage.setItem("actionery_data",JSON.stringify(actioneryStorage))
        }
      }
    }
  }
  
}