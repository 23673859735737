// Entry point for the build script in your package.json
import "./controllers"
import LocalTime from 'local-time'
import "@nathanvda/cocoon"

LocalTime.config.i18n["es"] = {
  date: {
    dayNames: [ "domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado" ],
    abbrDayNames: [ "dom", "lun", "mar", "mié", "jue", "vie", "sáb" ],
    monthNames: [ "enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre" ],
    abbrMonthNames: [ "ene", "feb", "mar", "abr", "mayo", "jun", "jul", "ago", "sep", "oct", "nov", "dic" ],
    yesterday: "ayer",
    today: "hoy",
    tomorrow: "mañana",
    on: "el {date}",
    formats: {
      default: "%e de %B del %Y",
      thisYear: "%e de %B"
    }
  },
  time: {
    am: "am",
    pm: "pm",
    singular: "un {time}",
    singularAn: "una {time}",
    elapsed: "hace {time}",
    second: "segundo",
    seconds: "segundos",
    minute: "minutos",
    minutes: "minutos",
    hour: "hora",
    hours: "horas",
    formats: {
      default: "%l:%M%P"
    }
  },
  datetime: {
    at: "{date} a las {time}",
    formats: {
      default: "%e de %B , %Y a las %l:%M%P %Z"
    }
  }
}

LocalTime.config.locale = "es"
LocalTime.start()